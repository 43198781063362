import React, { Component } from "react";
import './bank.css'
import i18n from "../../translations";

class BankInformation extends Component {
  render() {
    return (
      <div className="container">
        <h2>{i18n.t('bankTitle')}</h2>
        <div class="account-info">
          <p><strong>{i18n.t('bankNav')}:</strong> Suomen OP Pankki / OP Bank</p>
          <p><strong>{i18n.t('accountNumber')}:</strong> FI85 5720 1020 6076 99</p>
          <p><strong>{i18n.t('accountReceiver')}:</strong> Drita ry</p>
          <p><strong>{i18n.t('referenceNumber')}:</strong> {localStorage.getItem("inputValue")}</p>
        </div>
        <h3>{i18n.t('paymentInfo')}</h3>
      </div>

    )
  }
}

export default BankInformation;