import React, { useState, useEffect } from 'react';
import { SyncLoader } from 'react-spinners'
import i18n from '../../translations';
import '../../App.css';

const Payments = () => {
  const [inputValue, setInputValue] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [checkedState, setCheckedState] = useState(false)
  const [spinner, setSpinner] = useState(false);
  const [inputTypePassword, setInputTypePassword] = useState(true);

  const handleInputChange = (e) => {
    localStorage.setItem("inputValue", e.target.value);
    setInputValue(e.target.value);
  };

  const dateFormat = (e) => {
    if (!e) {
      return ''
    }
    let dateParts = e.split("-");
    let formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return formattedDate
  };

  const handleCheck = () => {
    localStorage.setItem("remember", !checkedState);
    localStorage.setItem("responseData", null);
    setCheckedState(!checkedState);
  };

  const handleInputType = () => {
    if (inputTypePassword) {
      setInputTypePassword(false)
    } else {
      setInputTypePassword(true)
    }
  }

  const formatMonth = (m) => {
    if (m <= 9) {
      return '0' + m
    }
    return m
  };

  useEffect(() => {
    if (localStorage.getItem("remember") === "true") {
      setCheckedState(true);
      setInputValue(localStorage.getItem("inputValue"));
      setResponseData(JSON.parse(localStorage.getItem("responseData")));
    } else {
      setCheckedState(false);

      setInputValue('');
      localStorage.removeItem("inputValue");

      setResponseData(null);
      localStorage.removeItem('responseData');
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true)

    try {
      const response = await fetch(`https://api.guidesoft.fi/antaresia/${inputValue}`);
      const data = await response.json();
      if (data.transactions.length !== 0 && data.sum.length !== 0) {
        setResponseData(data);
        if (checkedState) {
          localStorage.setItem('responseData', JSON.stringify(data));
        }
        setInputValue(inputValue);
        setError(null);
      } else {
        setResponseData(null);
        localStorage.removeItem('responseData');
        setError('No reference number found');
      }
    } catch (error) {
      setError('Invalid reference number');
      setResponseData(null);
      localStorage.removeItem('responseData');
      console.error('Error fetching data from database');
    }
    setSpinner(false)
  };

  return (
    <div>
      <div className='container-payments'>
        <form onSubmit={handleSubmit}>
          <h2>{i18n.t('title')}</h2>
          <input
            type={inputTypePassword ? 'text' : 'password'}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={i18n.t('referenceNumber')}
          />
          <button className="button-2" type="submit">{i18n.t('search')}</button>
          <SyncLoader className='spinner' color="#333" loading={spinner} size={10} />
          <br></br>
          <input type="checkbox" checked={inputTypePassword} onChange={handleInputType} />{i18n.t('showRefID')}
          <input type="checkbox" checked={checkedState} onChange={handleCheck} />{i18n.t('remember')}
        </form >
      </div >
      {error && (
        <div className="danger">
          <span>{error}</span>
        </div>
      )}
      {
        responseData &&
        <div className="container-payments">
          <div className='h3-container'>
            <h3 className="titles">{i18n.t('name')}: {responseData.sum[0].Name}</h3><h4>[Reg: {dateFormat(responseData.sum[0].RegistryDate)}]</h4>
          </div>
          <h2>{i18n.t('totalPayments')}</h2>
          <table>
            <thead>
              <tr>
                <td>{i18n.t('yearly')}</td>
                <td>{i18n.t('yearlyPayments')}</td>
                {
                  responseData.sum[0].Total >= 0 &&
                  <td>{i18n.t('totalPayments')}</td>
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Year">{new Date().getFullYear()}</td>
                <td data-label="This year">{responseData.sum[0].Yearly}€</td>
                {
                  responseData.sum[0].Total >= 0 &&
                  <td data-label="Total">{+responseData.sum[0].Total + +responseData.sum[0].Yearly}€</td>
                }
              </tr>
            </tbody>
          </table>
        </div>
      }

      {responseData &&
        <div className="container-payments">
          <h2>{i18n.t('monthlyPayments')}</h2>
          <table>
            <thead>
              <tr>
                <td>{i18n.t('month')}</td>
                <td>{i18n.t('payment')}</td>
              </tr>
            </thead>
            <tbody>
              {responseData.transactions.map((item) => (
                <tr>
                  <td data-label="Month">{new Date(item.Month).getFullYear()}-{formatMonth(Math.floor(new Date(item.Month).getMonth() + 1))}</td>
                  <td data-label="Payment">{item.Payment}€</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
};
export default Payments;
